import React from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import ExpandedTable from './ExpandedTable';
import { useTranslation } from 'react-i18next';
import ExcelTable from './ExcelTable';

export default function ItemDataTable({ data, expandedData }) {
  const { t } = useTranslation();

  const columns = [
    {
      Header: t('created-at'),
      accessor: 'createdAt',
    },
    {
      Header: t('key'),
      accessor: 'key',
    },
    {
      Header: t('value'),
      accessor: 'value',
    },
    {
      Header: t('creator'),
      accessor: 'creator',
    },
    // {
    //   Header: t('data-type'),
    //   accessor: 'dataType',
    // },
  ];
  return (
    <div style={{ backgroundColor: 'white', borderRadius: '4px' }}>
      <ReactTable
        data={data}
        defaultPageSize={10}
        pageSizeOptions={[10, 20, 50, 100]}
        filterable
        columns={columns}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
        nextText={t('next')}
        previousText={t('previous')}
        pageText={t('page')}
        ofText={t('of')}
        rowsText={t('rows')}
        style={{ borderRadius: '4px' }}
        SubComponent={(row) => {
          if (expandedData[row.index].dataType === 'customExcelData') {
            return (
              <div style={{ overflow: 'auto' }}>
                <ExcelTable rows={expandedData[row.index].value.data} />
              </div>
            );
          }
          return (
            <ExpandedTable
              displayPicture={'arr.original.displayPicture'}
              expandedData={expandedData[row.index]}
            />
          );
        }}
      />
    </div>
  );
}
