import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { HashLink as Link } from 'react-router-hash-link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/logo.png';
import { version } from '../../setup';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.common.black,
  },
  header: {
    backgroundColor: 'transparent',
    position: 'relative',
    height: 80,
    [theme.breakpoints.down('650')]: {
      // position: 'sticky',
    },
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(1.6, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.common.black,
  },
  footerRight: {
    [theme.breakpoints.down('600')]: {
      textAlign: 'center',
    },
    marginRight: theme.spacing(2),
    textAlign: 'right',
    color: theme.palette.primary.contrastText,
  },
  footerLeft: {
    [theme.breakpoints.down('600')]: {
      textAlign: 'center',
    },
    color: theme.palette.primary.contrastText,
    fontStyle: 'italic',
    marginRight: theme.spacing(2),
    textAlign: 'left',
  },
  slogan: {
    position: 'absolute',
    top: '10px',
    paddingTop: '10px',
  },
  link: {
    textDecoration: 'none',
    paddingRight: '20px',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('650')]: {
      padding: '15px',
    },
  },
}));

function Copyright() {
  return (
    <div style={{ color: '#8dabbd' }}>
      v. {version} &nbsp;
      {' © '}
      {new Date().getFullYear()}
      {' UNISOT '}
    </div>
  );
}

export default function HomePageLayout({ children }) {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLanguageOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleLanguageClose();
  };

  return (
    <div className={classes.root}>
      {/* Header */}
      <AppBar MuiPaperElevation4 className={classes.header}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Link to={`/home`}>
            <div className={classes.slogan}>
              <img src={logo} style={{ width: '200px' }} alt="UnisotLogo" />
            </div>
          </Link>
          <Box>
            <IconButton
              //size="large"
              edge="end"
              aria-label="switch language"
              aria-haspopup="true"
              onClick={handleLanguageOpen}
              style={{ fontSize: '0.8rem', color: '#8dabbd' }}
            >
              {i18n.language.toUpperCase()}
            </IconButton>
            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleLanguageClose}
            >
              <MenuItem
                style={{ fontSize: '0.8rem', color: '#8dabbd' }}
                onClick={() => handleSwitchLanguage('en')}
              >
                EN
              </MenuItem>
              <MenuItem
                style={{ fontSize: '0.8rem', color: '#8dabbd' }}
                onClick={() => handleSwitchLanguage('zh')}
              >
                ZH
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {children}
      {/* Footer */}
      <footer className={classes.footer}>
        <Container className={classes.footerLeft}>
          {/* Truth. Transparency. Trust. */}
        </Container>
        <Container className={classes.footerRight} maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
