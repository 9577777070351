import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function ExpandedTable({ expandedData }) {
  return (
    <div>
      <Typography variant="subtitle1" color="textSecondary">
        Txid:{' '}
        <a
          href={`https://whatsonchain.com/tx/${expandedData.txid}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {expandedData.txid}
        </a>
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Data type: {expandedData.dataType}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Creator: {expandedData.creator}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Key: {expandedData.key}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Value: {expandedData.value}
      </Typography>
    </div>
  );
}
