import React from 'react';
import { Provider } from 'react-redux';
import Router from './router/Routes';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

//
function App({ store }) {
  const [acceptConsent, setAcceptConsent] = React.useState(false);

  React.useEffect(() => {
    if (acceptConsent) {
      ReactGA.set({ anonymizeIp: true });
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }, [acceptConsent]);

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
      setAcceptConsent(true);
    }
  };

  const handleDeclineCookie = () => {
    setAcceptConsent(false);

    document.cookie
      .split(';')
      .filter((k) => !k.startsWith('CookieConsent'))
      .forEach(function (c) {
        if (c.trim().startsWith('_ga')) {
          console.log(c);
          document.cookie = c
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/'
            );
        }
      });
  };
  const val = getCookieConsentValue();
  console.log(val);
  return (
    <Provider store={store}>
      <Router />
      <CookieConsent
        setDeclineCookie
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        debug={val}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Provider>
  );
}

export default App;
