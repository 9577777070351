import React, { useEffect, useState } from 'react';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import CytoscapeComponent from 'react-cytoscapejs';
import { makeStyles } from '@material-ui/core/styles';
import DNAInfoBox from './DNAInfoBox';
import { breadthfirst, generateBorders, nodeAndEdgeStyle } from './utils';

cytoscape.use(dagre);

export default function ProductDNA({ data, showInfoBox = true }) {
  const itemId = data._id;

  const [selectedItem, setSelectedItem] = useState(null);
  const [elements, setElements] = useState([]);

  const createOnNodeClickHandler = () => (event) => {
    const nodeElement = event.target;
    const { data } = nodeElement._private;
    setSelectedItem({ data });
  };

  function generateData(d) {
    const ele = [];
    if (d.nodes && d.nodes.length > 0) {
      for (const node of d.nodes) {
        ele.push({
          data: {
            ...node.classification,
            ...node,
            borderColor: 'gray',
            borderWidth: 5,
          },
        });
      }
    }
    if (d.edges && d.edges.length > 0) {
      for (const edge of d.edges) {
        ele.push({ data: { ...edge } });
      }
    }

    setElements(ele);
    const selected = ele.find((d) => d.data._id === itemId);
    setSelectedItem(selected);
  }

  useEffect(() => {
    const onNodeClickHandler = createOnNodeClickHandler();
    cyCallback.on('tap', 'node', onNodeClickHandler);
    generateData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const updatedElements = generateBorders(elements, itemId, selectedItem);
      setElements(updatedElements);
      cyCallback.layout(breadthfirst).run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const classes = useStyles();
  let cyCallback = { on: () => {} };

  return (
    <div className={classes.container}>
      <div
        className={classes.graph}
        style={{ height: showInfoBox ? '50vh' : '20rem' }}
      >
        <CytoscapeComponent
          cy={(cy) => (cyCallback = cy)}
          elements={elements}
          layout={breadthfirst}
          style={styles.cytoscape}
          stylesheet={nodeAndEdgeStyle}
        />
      </div>
      {showInfoBox && <DNAInfoBox data={selectedItem} />}
    </div>
  );
}

export const DNAshadow =
  '0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';
export const DNAColors = {
  textDark: '#04182B',
  textBright: '#F0F4F6',
  textMedium: '#717788',
};

const styles = {
  cytoscape: {
    borderRadius: 5,
    backgroundColor: 'white',
    // boxShadow: DNAshadow,
    height: '99%',
    width: '100%',
  },
};

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  graph: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));
