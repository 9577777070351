import { createSelector } from 'reselect';

export const selectAllData = (state) => state.mapReducer.genericData.data;

//Tracking points
export const selectTrackingPoints = createSelector(
  selectAllData,
  (TrackingPoints) =>
    TrackingPoints
      ? TrackingPoints.data
          .filter((e) => e.dataType === 'GeoJSON')
          .reduce((all, elem, index) => {
            const arr = [
              elem.data[0].geometry.coordinates[1],
              elem.data[0].geometry.coordinates[0],
            ];
            all.push(arr);
            return all;
          }, [])
      : []
);

//Points on the Map
export const selectPointsOnMap = createSelector(selectAllData, (Points) =>
  Points
    ? Points.data
        .filter((e) => e.dataType === 'GeoJSON')
        .reduce((all, elem, index) => {
          if (!all.features) {
            all = {
              type: 'FeatureCollection',
              features: [],
            };
            elem.data[0].properties.creator = elem.creator.paymailHandle;
            all.owner = elem.creator.paymailHandle;
            all.features.unshift(elem.data[0]);
          } else {
            all.owner = elem.creator.paymailHandle;
            elem.data[0].properties.creator = elem.creator.paymailHandle;
            all.features.unshift(elem.data[0]);
          }
          return all;
        }, [])
    : []
);

//Color creators
export const selectColorsCreators = createSelector(selectAllData, (Colors) =>
  Colors
    ? Colors.data
        .filter((elem, index) => {
          return elem.dataType === 'GeoJSON';
        })
        .reduce((all, elem, index) => {
          let temp = {
            owner: elem.creator.paymailHandle,
            ownerColor: elem.data[0].properties.markerColor,
          };
          all.push(temp);
          return all;
        }, [])
        .reduce((all, elem, index) => {
          let temp = elem.owner.toString();
          let filteredResults = Colors.data.filter((elem, index) => {
            return elem.dataType === 'GeoJSON';
          });
          let arr = filteredResults.reduce((all, elem, index) => {
            let temp = {
              owner: elem.creator.paymailHandle,
              ownerColor: elem.data[0].properties.markerColor,
            };
            all.push(temp);
            return all;
          }, []);

          let match = arr.findIndex((elem) => elem.owner === temp);
          if (match === index) {
            all.push(elem);
          }
          return all;
        }, [])
    : null
);

//Traceability
export const selectTraceability = createSelector(
  selectAllData,
  (Traceability) => (Traceability ? Traceability.transfers : [])
);

//Product DNA

export const selectProductDNA = createSelector(selectAllData, (ProductDna) =>
  ProductDna ? ProductDna.dna : {}
);
