import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  displayPictureLink: {
    width: '80%',
    alignSelf: 'center',
    marginTop: '45px',
  },
  displayName: {
    width: '250px',
    height: '45px',
    alignSelf: 'center',
    marginLeft: '40px',
  },
  picture: {
    [theme.breakpoints.down('xs')]: {
      height: '90px',
    },
    borderRadius: '20%',
    height: '120px',
  },
}));

export default function LandingPageSkeleton(props) {
  const { loading = false } = props;
  const classes = useStyles();

  return (
    !loading.length && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        className={classes.displayPictureLink}
      >
        <Skeleton
          className={classes.picture}
          variant="circle"
          animation="wave"
          width={120}
        />
        <Skeleton className={classes.displayName} variant="rect" />
      </div>
    )
  );
}
