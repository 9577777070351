import { createSelector } from 'reselect';
import { formatDateTime } from 'api/utils/utils';
export const selectAll = (state) => state.mapReducer.genericData;
export const selectAllData = (state) => state.mapReducer.genericData.data;

//transaction ID
export const selectTransaction = createSelector(selectAll, (Transaction) =>
  Transaction.shareItem ? Transaction.shareItem.itemId : ''
);

export const selectIdentifier = createSelector(selectAllData, (Data) =>
  Data ? Data.data?.filter((elem) => elem.dataType === 'identifier') : []
);

//All Temperatures
export const selectAllTemperatures = createSelector(selectAllData, (Data) => {
  return Data
    ? Data.data
        ?.filter(
          (elem) =>
            elem.dataType === 'Temperature' || elem.dataType === 'temperature'
        )
        .map((data) => data.data)
        .reduce((all, elem) => {
          const val = elem.values ? elem.values[0].key : elem.key;
          const isoDate = new Date(
            elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
          );
          const browserHoursOffset =
            new Date(
              elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
            ).getTimezoneOffset() / 60;
          const reconciliation =
            isoDate.setHours(isoDate.getHours() - browserHoursOffset) / 1000;

          if (!all[val]) {
            all[val] = [];
          }
          all[val].push({
            time: reconciliation,
            value: parseFloat(
              elem.metadata.unit
                ? elem.metadata.unit === 'fahrenheit'
                  ? ((elem.value - 32) * 5) / 9
                  : elem.value
                : elem.values[0].value.split(' ')[1] === 'fahrenheit'
                ? ((elem.values[0].value.split(' ')[0] - 32) * 5) / 9
                : elem.values[0].value.split(' ')[0]
            ),
            metadata: {
              chartColor: elem.metadata.chartColor,
              unit: ' celsius',
            },
          });

          return all;
        }, {})
    : {};
});

//Weight
export const selectAllWeights = createSelector(selectAllData, (Weight) =>
  Weight
    ? Weight.data
        ?.filter(
          (elem) => elem.dataType === 'Weight' || elem.dataType === 'weight'
        )
        .map((data) => data.data)
        .reduce((all, elem) => {
          const val = elem.values ? elem.values[0].key : elem.key;
          const isoDate = new Date(
            elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
          );
          const browserHoursOffset =
            new Date(
              elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
            ).getTimezoneOffset() / 60;
          const reconciliation =
            isoDate.setHours(isoDate.getHours() - browserHoursOffset) / 1000;

          if (!all[val]) {
            all[val] = [];
          }
          all[val].push({
            time: reconciliation,
            value: parseFloat(
              elem.metadata.unit
                ? elem.metadata.unit === 'g'
                  ? elem.value / 1000
                  : elem.value
                : elem.values[0].value.split(' ')[1] === 'g'
                ? elem.values[0].value.split(' ')[0] / 1000
                : elem.values[0].value.split(' ')[0]
            ),
            metadata: {
              chartColor: elem.metadata.chartColor,
              unit: ' kg',
            },
          });

          return all;
        }, {})
    : {}
);
//Energy
export const selectAllEnergy = createSelector(selectAllData, (Energy) =>
  Energy
    ? Energy.data
        ?.filter(
          (elem) => elem.dataType === 'Energy' || elem.dataType === 'energy'
        )
        .map((data) => data.data)
        .reduce((all, elem) => {
          const val = elem.values ? elem.values[0].key : elem.key;
          const isoDate = new Date(
            elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
          );
          const browserHoursOffset =
            new Date(
              elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
            ).getTimezoneOffset() / 60;
          const reconciliation =
            isoDate.setHours(isoDate.getHours() - browserHoursOffset) / 1000;

          if (!all[val]) {
            all[val] = [];
          }
          all[val].push({
            time: reconciliation,
            value: Number(
              elem.values ? elem.values[0].value.split(' ')[0] : elem.value
            ),
            metadata: {
              chartColor: elem.metadata.chartColor,
              unit: elem.metadata.unit
                ? elem.metadata.unit
                : elem.values[0].value.split(' ')[1],
            },
          });

          return all;
        }, {})
    : {}
);

//Volume
export const selectAllVolume = createSelector(selectAllData, (Energy) =>
  Energy
    ? Energy.data
        ?.filter(
          (elem) => elem.dataType === 'volume' || elem.dataType === 'Volume'
        )
        .map((data) => data.data)
        .reduce((all, elem) => {
          const val = elem.values ? elem.values[0].key : elem.key;
          const isoDate = new Date(
            elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
          );
          const browserHoursOffset =
            new Date(
              elem.metadata.timestamp ? elem.metadata.timestamp : elem.timestamp
            ).getTimezoneOffset() / 60;
          const reconciliation =
            isoDate.setHours(isoDate.getHours() - browserHoursOffset) / 1000;

          if (!all[val]) {
            all[val] = [];
          }
          all[val].push({
            time: reconciliation,
            value: Number(
              elem.values ? elem.values[0].value.split(' ')[0] : elem.value
            ),
            metadata: {
              chartColor: elem.metadata.chartColor,
              unit: elem.values
                ? elem.values[0].value.split(' ')[1]
                : elem.metadata.unit,
            },
          });

          return all;
        }, {})
    : {}
);

// Card
export const selectCardInfo = createSelector(selectAllData, (Classification) =>
  Classification
    ? {
        urlImage: Classification.classification.displayPicture,
        urlImageBg: Classification.classification.coverPhoto,
        txid: Classification.txid,
        name: Classification.classification.displayName,
      }
    : {}
);

//Data to fill table in the infoPage
export const selectAllDataToFillTable = createSelector(selectAllData, (Data) =>
  Data
    ? Data.data
        ?.filter((elem) => elem.dataType)
        .filter((elem) => elem.dataType !== 'unknown')
        .reduce((all, elem) => {
          const val = {};

          val.createdAt = formatDateTime(elem.data.metadata?.timestamp);
          switch (elem.dataType) {
            case 'GeoJSON':
              val.key = elem.data[0].properties?.name;
              val.value = elem.data[0].properties?.formattedAddress;
              break;
            case 'date':
              val.key = elem.data.values
                ? elem.data.values[0].key
                : elem.data.key;
              val.value = formatDateTime(
                elem.data.values ? elem.data.values[0].value : elem.data.value
              );
              break;
            case 'Date':
              val.key = elem.data.values
                ? elem.data.values[0].key
                : elem.data.key;
              val.value = formatDateTime(
                elem.data.values ? elem.data.values[0].value : elem.data.value
              );
              break;
            case 'food-form':
              val.key = 'Food Form';
              break;
            case 'Food form':
              val.key = 'Food Form';
              break;
            case 'Food Form':
              val.key = 'Food Form';
              break;
            case 'customExcelData':
              val.key = 'Parsed .xlsx';
              val.value = 'Parsed .xlsx';
              break;
            case 'gs1-identifier':
              val.key = 'GS1 identifier';
              val.value = JSON.stringify(elem.data);
              break;
            default:
              val.key = elem.data
                ? elem.data.values
                  ? elem.data.values[0].key
                  : elem.data.key
                : 'uknown';
              val.value = elem.data
                ? elem.data.values
                  ? elem.data.values[0].value
                  : elem.data.value
                : 'uknown';
              break;
          }
          val.creator = elem.creator.paymailHandle;
          val.dataType = elem.dataType;

          all.push(val);
          return all;
        }, [])
    : []
);

//Data to the expandable of the table
export const selectAllExpandedData = createSelector(selectAllData, (Data) =>
  Data
    ? Data.data
        ?.filter((elem) => elem.dataType)
        .filter((elem) => elem.dataType !== 'unknown')
        .reduce((all, elem) => {
          const val = {};
          val.txid = elem.txid;
          val.dataType = elem.dataType;
          val.creator = elem.creator.paymailHandle;
          switch (elem.dataType) {
            case 'GeoJSON':
              val.key = elem.data[0].properties?.name;
              val.value = elem.data[0].properties?.formattedAddress;
              break;
            case 'date':
              val.key = elem.data.values
                ? elem.data.values[0].key
                : elem.data.key;
              val.value = formatDateTime(
                elem.data.values ? elem.data.values[0].value : elem.data.value
              );
              break;
            case 'Date':
              val.key = elem.data.values
                ? elem.data.values[0].key
                : elem.data.key;
              val.value = formatDateTime(
                elem.data.values ? elem.data.values[0].value : elem.data.value
              );
              break;
            case 'food-form':
              val.key = 'Food Form';
              break;
            case 'Food form':
              val.key = 'Food Form';
              break;
            case 'Food Form':
              val.key = 'Food Form';
              break;
            case 'customExcelData':
              val.key = 'Parsed .xlsx';
              val.value = elem.data;
              break;
            case 'gs1-identifier':
              val.key = 'GS1 identifier';
              val.value = JSON.stringify(elem.data);
              break;
            default:
              val.key = elem.data.values
                ? elem.data.values[0].key
                : elem.data.key;
              val.value = elem.data.values
                ? elem.data.values[0].value
                : elem.data.value;
              break;
          }
          all.push(val);
          return all;
        }, [])
    : []
);
