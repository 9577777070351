import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';
//icons
import ArrowBack from '@material-ui/icons/ArrowBack';
//Redux
import { useSelector } from 'react-redux';
//Components
import Contour from '../../components/common/Contour';
import LightweightChart from 'component-factory/lightweightChart';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
//Selectors
import { selectAllEnergy, selectTransaction } from '../data-page/selectors';
import { useTranslation } from 'react-i18next';

const BlackTextTypography = withStyles({
  root: {
    color: 'black',
  },
})(Typography);
const WhiteTextTypography = withStyles({
  root: {
    color: 'white',
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
  page: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
    margin: '40px',
    marginTop: 0,
  },
  chartPage: {
    [theme.breakpoints.down('xs')]: {
      height: '450px',
    },
    paddingTop: 30,
    height: '390px',
  },

  chart: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 2,
      paddingRight: 0,
      height: 280,
    },
    paddingLeft: 2,
    paddingRight: 90,
    height: '100%',
    width: '100%',
  },
  outBox: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  insideBox: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
      minWidth: 'none',
      width: '100%',
      flexDirection: 'row',
      marginBottom: 4,
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '40%',
    maxWidth: 150,
    minWidth: 145,
    paddingBottom: 3,
  },
  boxLevel3: {
    [theme.breakpoints.down('xs')]: {
      width: '33.3%',
      height: '100%',
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '30.8%',
    paddingRight: 10,
  },
  arrowBack: {
    color: '#024266',
    marginTop: '20px',
    marginLeft: '40px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7%',
  },
}));
export default function EnergyPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const accessItemTransaction = useSelector(selectTransaction);
  const allEnergy = useSelector(selectAllEnergy);
  const { t } = useTranslation();

  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <>
      <Contour title={t('energy')} color={theme.palette.secondary.main} />
      <ArrowBack
        fontSize="large"
        className={classes.arrowBack}
        onClick={back}
      />
      {Object.keys(allEnergy).map(
        (key, i) =>
          allEnergy[key].length > 1 && (
            <div className={classes.page}>
              <Paper square className={classes.root}>
                {allEnergy[key] !== null && allEnergy[key].length > 0 ? (
                  <div className={classes.chartPage}>
                    <div></div>
                    <BlackTextTypography variant="h3">
                      {key}
                    </BlackTextTypography>
                    <Box className={classes.outBox}>
                      <Box className={classes.insideBox}>
                        <Box
                          className={classes.boxLevel3}
                          bgcolor={theme.palette.primary.light}
                        >
                          <BlackTextTypography variant="h2">
                            {allEnergy[key] &&
                              allEnergy[key]
                                .reduce((a, b) => (a.value < b.value ? a : b))
                                .value.toFixed(2)}{' '}
                            {allEnergy[key][0].metadata.unit}
                          </BlackTextTypography>
                          <div style={{ color: 'black' }}>{t('min')}</div>
                        </Box>
                        <Box
                          className={classes.boxLevel3}
                          bgcolor={theme.palette.secondary.main}
                        >
                          <WhiteTextTypography variant="h2">
                            {allEnergy[key] &&
                              allEnergy[key]
                                .reduce((a, b) => (a.value > b.value ? a : b))
                                .value.toFixed(2)}{' '}
                            {allEnergy[key][0].metadata.unit}
                          </WhiteTextTypography>
                          <div style={{ color: '#fff' }}>{t('max')}</div>
                        </Box>
                        <Box
                          className={classes.boxLevel3}
                          bgcolor={theme.palette.secondary.dark}
                        >
                          <WhiteTextTypography variant="h2">
                            {allEnergy[key] &&
                              (
                                allEnergy[key].reduce(
                                  (total, next) => total + next.value,
                                  0
                                ) / allEnergy[key].length
                              ).toFixed(2)}{' '}
                            {allEnergy[key][0].metadata.unit}
                          </WhiteTextTypography>
                          <div style={{ color: '#fff' }}>{t('avg')}</div>
                        </Box>
                      </Box>
                      <div className={classes.chart}>
                        {accessItemTransaction.length !== 0 && (
                          <LightweightChart
                            area={allEnergy[key]}
                            topColor={
                              allEnergy[key][0].metadata.chartColor || 'yellow'
                            }
                            lineColor={
                              allEnergy[key][0].metadata.chartColor || '#c9a500'
                            }
                            bottomColor="rgba(0, 0, 0, 0.04)"
                            legend={allEnergy[key][0].metadata.unit}
                          />
                        )}
                      </div>
                    </Box>
                  </div>
                ) : (
                  <Typography className={classes.noData} variant="h2">
                    {t('no-energy-data')}
                  </Typography>
                )}
              </Paper>
            </div>
          )
      )}
    </>
  );
}
