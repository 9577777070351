import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { HashLink as Link } from 'react-router-hash-link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import Countdown from './CountdownComponent';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//icons
import logo from '../../assets/logo.png';
//apiService
import { trackingActions } from 'api/APIService';
//Selectors
import { selectExpiration } from '../../store/selectors';

import { selectTransaction } from '../../pages/data-page/selectors';
import { version } from '../../setup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    backgroundImage: 'url(https://i.imgur.com/KTQ0AVR.jpg)',
    position: 'relative',
    [theme.breakpoints.down('750')]: {
      // position: 'sticky',
    },
    padding: '5px 10px',
  },

  footer: {
    display: 'flex',
    padding: theme.spacing(1.6, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  footerRight: {
    [theme.breakpoints.down('600')]: {
      textAlign: 'center',
    },
    marginRight: theme.spacing(2),
    textAlign: 'right',
    color: theme.palette.secondary.contrastText,
  },
  footerLeft: {
    [theme.breakpoints.down('600')]: {
      textAlign: 'center',
    },
    color: theme.palette.secondary.contrastText,
    fontStyle: 'italic',
    marginRight: theme.spacing(2),
    textAlign: 'left',
  },
}));

function Copyright() {
  return (
    <div style={{ color: '#8dabbd' }}>
      v. {version} &nbsp;
      {' © '}
      {new Date().getFullYear()}
      {' UNISOT '}
    </div>
  );
}

export default function PageLayout({ children }) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const expirationDate = useSelector(selectExpiration);
  const accessItemTransaction = useSelector(selectTransaction);
  const param = children[1].props.match.params.id;
  const param2 = children[1].props.match.params.id2;
  const param3 = children[1].props.match.params.id3;
  const param4 = children[1].props.match.params.id4;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onInit = function () {
    if (accessItemTransaction.length === 0) {
      if (
        param.length > 0 &&
        param !== '01' &&
        param2 !== undefined &&
        param2.length > 0
      ) {
        dispatch(
          trackingActions.fetchGenericData({
            trackingData: `${param}/${param2}`,
          })
        );
      } else if (
        param.length > 0 &&
        param !== '01' &&
        param !== 'ef316d98c555412698110381997a6c94' &&
        param2 === undefined
      ) {
        dispatch(
          trackingActions.fetchAnchorData({ trackingAnchorData: `${param}` })
        );
      } else if (param === 'ef316d98c555412698110381997a6c94') {
        dispatch(
          trackingActions.fetchShareByResolvingType({
            resolvingType: 'itemTemplate',
            resolvingTag: `01/${param}`,
          })
        );
      } else if (param.length > 0 && param === '01' && param3 === '21') {
        dispatch(
          trackingActions.fetchShareByResolvingType({
            resolvingType: 'item',
            resolvingTag: `${param}/${param2}/${param3}/${param4}`,
          })
        );
      } else if (param.length > 0 && param === '01' && param3 === undefined) {
        dispatch(
          trackingActions.fetchShareByResolvingType({
            resolvingType: 'itemTemplate',
            resolvingTag: `${param}/${param2}`,
          })
        );
      }
    }
  };

  React.useEffect(onInit, []);

  const handleLanguageOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleLanguageClose();
  };

  return (
    <div className={classes.root}>
      {/* Header */}
      <Countdown expiration={expirationDate} />
      <AppBar elevation={0} className={classes.header}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Link to={`/info/${param}${param2 ? '/' + param2 : ''}`}>
            <img src={logo} style={{ width: '200px' }} alt="UnisotLogo" />
          </Link>
          <Box>
            <IconButton
              //size="large"
              edge="end"
              aria-label="switch language"
              aria-haspopup="true"
              onClick={handleLanguageOpen}
              style={{ fontSize: '0.8rem', color: '#8dabbd' }}
            >
              {i18n.language.toUpperCase()}
            </IconButton>
            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleLanguageClose}
            >
              <MenuItem
                style={{ fontSize: '0.8rem', color: '#8dabbd' }}
                onClick={() => handleSwitchLanguage('en')}
              >
                EN
              </MenuItem>
              <MenuItem
                style={{ fontSize: '0.8rem', color: '#8dabbd' }}
                onClick={() => handleSwitchLanguage('zh')}
              >
                ZH
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {children}
      {/* Footer */}
      <footer className={classes.footer}>
        {/* <Container className={classes.footerLeft}> */}
        {/* Truth. Transparency. Trust. */}
        {/* </Container> */}
        <Container className={classes.footerRight} maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
