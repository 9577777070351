import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatDateTime } from 'api/utils/utils';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function DNAInfoBox({ data }) {
  const classes = useStyles();
  const [expanded] = useState(true);
  // const history = useHistory()
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const {
    createdAt,
    creator,
    description,
    displayName,
    displayPicture,
    id,
    owner,
  } = data.data;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.displayWrapper}>
          {displayPicture && (
            <img
              alt=""
              className={classes.displayPicture}
              src={displayPicture}
            />
          )}
          {displayName && (
            <div>
              <Typography variant="h6">{displayName}</Typography>
            </div>
          )}
        </div>
        {/* <div
          onClick={pressedToggleExpanded}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            opacity: 0.5,
          }}
        >
          <Typography
            style={{ color: 'black', fontSize: '0.7rem', userSelect: 'none' }}
          >{`${expanded ? 'Hide' : 'Show'} info`}</Typography>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div> */}
      </div>
      {expanded && (
        <div className={classes.infoContent}>
          {id && (
            <div className={classes.wrapper1}>
              <Typography variant="body2">TXID</Typography>
              <Typography variant="subtitle1">{`${id.substr(
                0,
                5
              )}...`}</Typography>
            </div>
          )}
          {createdAt && (
            <div className={classes.wrapper}>
              <Typography variant="body2">{t('created-at')}</Typography>
              <Typography variant="subtitle1">
                {formatDateTime(createdAt)}
              </Typography>
            </div>
          )}
          {creator && (
            <div className={classes.wrapper}>
              <Typography variant="body2">{t('creator')}</Typography>
              <Typography variant="subtitle1">
                {creator.paymailHandle}
              </Typography>
            </div>
          )}
          {owner && (
            <div className={classes.wrapper}>
              <Typography variant="body2">{t('owner')}</Typography>
              <Typography variant="subtitle1">{owner.paymailHandle}</Typography>
            </div>
          )}
          {description && (
            <div className={classes.wrapper1}>
              <Typography variant="body2">{t('description')}</Typography>
              <Typography variant="subtitle1">{description}</Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '2%',
    width: '100%',
  },
  displayPicture: {
    borderRadius: '50%',
    height: '2rem',
    marginRight: '0.5rem',
    width: '2rem',
  },
  displayWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  infoContent: {
    borderWidth: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '15px',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    color: 'black',
    fontFamily: 'Montserrat, sansSerif',
    fontSize: '0.8rem',
    margin: 0,
  },
  subHeaderText: {
    color: 'black',
    fontWeight: 'bold',
  },
  goToItemButton: {
    backgroundColor: '#002144',
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    userSelect: 'none',
  },
  headerText: {
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  wrapper1: {
    marginTop: '1rem',
    marginLeft: '1rem',
  },
  wrapper: {
    marginTop: '1rem',
    marginLeft: '2rem',
  },
  buttonText: {
    color: 'rgba(255, 255, 255, 0.85)',
    fontWeight: 'bold',
  },
}));
