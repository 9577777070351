import React from 'react';
import Hiro from './Hiro';
import QrReader from 'react-qr-reader';
import { makeStyles } from '@material-ui/core/styles';
import ReactDom from 'react-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/logo.png';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
//apiService
import { trackingActions } from 'api/APIService';
//selectors
import { selectItemDesc } from './selectors';

const useStyles = makeStyles((theme) => ({
  slogan: {
    position: 'absolute',
    top: '10px',
    paddingTop: '10px',
  },
  header: {
    backgroundColor: 'transparent',
    position: 'relative',
    [theme.breakpoints.down('750')]: {},
  },
}));

const home = (e) => {
  e.stopPropagation();
  window.location.href = '/home';
};
const LogoHeader = () => {
  const classes = useStyles();

  return ReactDom.createPortal(
    <div
      style={{
        position: 'fixed',
        top: '5px',
        left: '5px',
        width: '100%',
        zIndex: '2',
      }}
    >
      <AppBar MuiPaperElevation4 className={classes.header}>
        <Toolbar>
          <div
            onClick={home}
            style={{ cursor: 'pointer', width: '-webkit-fill-available' }}
            className={classes.slogan}
          >
            <img src={logo} style={{ width: '200px' }} alt="UnisotLogo" />
          </div>
        </Toolbar>
      </AppBar>
    </div>,
    document.getElementById('root')
  );
};

export default function ARPage({ props }) {
  const dispatch = useDispatch();
  const [result, setResult] = React.useState('');
  const itemDesc = useSelector(selectItemDesc);

  const handleScan = (data) => {
    if (data) {
      //WIP
      const arg1 = data.slice(29, 53);
      const arg2 = data.slice(54, 151);
      if (data.length > 0) {
        dispatch(
          trackingActions.fetchGenericData({ trackingData: `${arg1}/${arg2}` })
        );
      }
      setResult(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <LogoHeader />
      {/* WIP */}
      {result.length === 0 ? (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          showViewFinder={false}
          className="qrreader"
        />
      ) : (
        <Hiro desc={itemDesc} />
      )}
    </>
  );
}
