import React from 'react';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  contour: {
    padding: '10px',
    paddingLeft: '50px',
    color: 'white',
    fontSize: '25px',
  },
}));

export default function Contour({ title, color }) {
  const classes = useStyles();

  return (
    <div
      className={classes.contour}
      style={{
        backgroundColor: '#fff',
      }}
    >
      <Fade timeout={600} in>
        <Typography variant="h6">{title}</Typography>
      </Fade>
    </div>
  );
}
