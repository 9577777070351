import React from 'react';
import Countdown from 'react-countdown-now';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  alert: {
    [theme.breakpoints.down('md')]: {
      left: 0,
      right: 0,
      position: 'relative',
    },

    position: 'absolute',
    padding: 0,
    left: 430,
    right: 430,
    justifyContent: 'center',

    backgroundColor: '#CFAF4E',
    color: '#04182B',
    zIndex: 1101,
  },
}));

export default function Counter({ expiration }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (expiration) {
    const expirationDate = new Date(expiration);
    const milliseconds = expirationDate.getTime();
    const Completionist = () => window.location.assign('https://unisot.com/');

    // Renderer callback with condition
    const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        return (
          <span>
            {days > 1 ? days + ' days ' : days === 1 ? days + 'day ' : null}{' '}
            {hours > 1
              ? hours + ' hours '
              : hours === 1
              ? hours + 'hour '
              : null}{' '}
            {minutes > 1
              ? minutes + ' minutes '
              : minutes === 1
              ? minutes + 'minute '
              : null}{' '}
          </span>
        );
      }
    };

    return (
      <Alert className={classes.alert} variant="filled" severity="warning">
        {t('this-link-expires-in')}{' '}
        {<Countdown date={milliseconds} renderer={renderer}></Countdown>}
      </Alert>
    );
  } else {
    return null;
  }
}
