import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //marginBottom: 30,
  },
  desc: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  showMore: {
    cursor: 'pointer',
    fontSize: '0.75rem',
    textAlign: 'right',
  },
  collapsedMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  collapsedDesktop: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  separator: {
    width: '100%',
    height: '2px',
    marginTop: '20px',
    marginBottom: '20px',
    color: '#ddd',
    backgroundColor: '#ddd',
    borderColor: 'none',
  },
}));

export default function DTDescription({ desc = '' }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const [maxSymbolsAllowed, setMaxSymbolsAllowed] = React.useState(90);
  const [description, setDescription] = React.useState('');

  const handleExpandClick = () => {
    if (expanded) {
      setMaxSymbolsAllowed(90);
    } else {
      setMaxSymbolsAllowed(desc.length);
    }
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (desc?.length >= maxSymbolsAllowed) {
      const transformed = desc?.substr(0, maxSymbolsAllowed);
      // 3 is for the number of dots ( the elipsis )
      setDescription(expanded ? transformed : `${transformed}...`);
    } else {
      setDescription(desc);
    }
  }, [desc, maxSymbolsAllowed, expanded]);

  return (
    <div className={classes.root}>
      <Typography variant="h6">{t('description')}</Typography>
      <Grid container spacing={3} style={{ marginTop: '3px' }}>
        <Grid item xs={12}>
          {/* mobile */}
          <Typography
            className={clsx(classes.desc, classes.collapsedMobile)}
            variant="body1"
          >
            {description}
          </Typography>
          {/* desktop */}
          <Typography
            className={clsx(classes.desc, classes.collapsedDesktop)}
            variant="body1"
          >
            {desc}
          </Typography>
          <div
            className={clsx(classes.collapsedMobile, classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <Typography
              variant="button"
              style={{ color: '#024266', marginTop: '15px' }}
            >
              {' '}
              {expanded ? 'Show less' : 'Show more'}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

DTDescription.propTypes = {
  desc: propTypes.string,
};
