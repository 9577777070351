import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import theme from '../../theme';

//icons
import RepeatIcon from '@material-ui/icons/Repeat';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  fodasse: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default function CustomizedTimeline({ transfers }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Timeline align="alternate">
      {transfers.map((transfer, iteration) => (
        <React.Fragment key={iteration}>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="h3">
                {' '}
                {/*color="textSecondary"> */}
                {moment(transfer.timestampTransfer).format('HH:mm')}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                style={{ backgroundColor: theme.palette.secondary.light }}
              >
                <CheckIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  {t('accepted-transfer')}
                </Typography>
                <div>
                  {t('accepted-from')} {transfer.previousOwner.paymailHandle}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="h3">
                {' '}
                {iteration === transfers.length - 1 ||
                new Date(transfer.timestampTransfer).getDate() >
                  new Date(
                    transfers[Math.abs(iteration + 1)]['timestampTransfer']
                  ).getDate() ? (
                  <div>
                    {moment(transfer.timestampTransfer).format(
                      'DD.MM.YYYY, HH:mm'
                    )}
                  </div>
                ) : (
                  <div>
                    {moment(transfer.timestampTransfer).format('HH:mm')}
                  </div>
                )}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <RepeatIcon color="primary" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  {t('transfer')}
                </Typography>
                <div>
                  {t('transfer-from')} {transfer.previousOwner.paymailHandle}{' '}
                  {t('to')} {transfer.currentOwner.paymailHandle}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </React.Fragment>
      ))}
    </Timeline>
  );
}

/**
 * @TO-DO Tracking-app
 * porptypes
 */
