import { createSelector } from 'reselect';

export const selectAllData = (state) => state.mapReducer.genericData.data;

//WIP
//Item Description
//Current owner

export const selectItemDesc = createSelector(selectAllData, (Desc) =>
  Desc
    ? {
        urlImage: Desc.classification.displayPicture,
        displayName: Desc.classification.displayName,
        paymailHandle: Desc.creator.paymailHandle,
        transfers: Desc.transfers,
        dates: Desc.data.reduce((all, elem) => {
          const temp = [];
          if (elem.data[0].key === 'Use By Date') {
            all.push(elem.data[0]);
          }
          if (elem.data[0].key === 'Production Date') {
            all.push(elem.data[0]);
          }
          return [...all, ...temp];
        }, []),
        expired: Desc.data
          .filter((elem) => elem.data[0].key === 'Use By Date')
          .filter((elem) => {
            const today = new Date().getTime();
            const expireDate = new Date(elem.data[0].value).getTime();
            return expireDate < today;
          }).length,
      }
    : {}
);
