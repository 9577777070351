import { createSelector } from 'reselect';
import moment from 'moment';

export const selectAll = (state) => state.mapReducer.genericData;
export const selectNonExistence = (state) => state.mapReducer.genericDataError;
export const selectAllData = (state) => state.mapReducer.genericData.data;

//None existence data
export const selectNonExistenceItem = createSelector(
  selectNonExistence,
  (NonExistence) => NonExistence
);

//Access
export const selectAccessabilityItem = createSelector(selectAll, (Access) =>
  Access.shareItem ? Access.shareItem.access === 'PRIVATE' : null
);

//Link expiration
export const selectExpiration = createSelector(selectAll, (Date) => {
  return Date.shareItem ? Date.shareItem.endOfLife : null;
});

//Select dataType protocol (SFC or Unisot ATM)
export const selectProtocol = createSelector(selectAllData, (protocol) =>
  protocol
    ? protocol.data
        .filter((e) => e.dataType === 'SFCKeyValue')
        .reduce((all, elem, index) => {
          let name = elem.dataType;
          all = name;
          return all;
        }, '')
    : ''
);

//Item Description
export const selectItemDesc = createSelector(selectAllData, (Desc) =>
  Desc ? Desc.classification.displayName : null
);

//Item TXID
export const selectTxid = createSelector(selectAllData, (item) =>
  item ? item.txid : null
);

//Current owner
export const selectCurrentOwner = createSelector(selectAllData, (Desc) =>
  Desc ? Desc.owner.paymailHandle : null
);

//All data sources
export const selectDataSource = createSelector(selectAllData, (Desc) =>
  Desc
    ? Desc.data.reduce(function (acc, obj) {
        if (!acc.find((arr) => arr.owner === obj.creator.paymailHandle)) {
          acc.push({});
          acc[acc.length - 1].owner = obj.creator.paymailHandle;
          acc[acc.length - 1].ownerData = {};
          acc[acc.length - 1].ownerData.location = [];
          acc[acc.length - 1].ownerData.temperature = [];
          acc[acc.length - 1].ownerData.weight = [];

          if (obj.dataType === 'GeoJSON') {
            acc[acc.length - 1].ownerData.location.push(obj);
          } else if (obj.dataType === 'senML') {
            acc[acc.length - 1].ownerData[obj.data[0].n].push(obj);
          }
        } else {
          let indexOwner = acc.findIndex(
            (a) => a.owner === obj.creator.paymailHandle
          );
          if (!acc[indexOwner].ownerData.location) {
            acc[indexOwner].ownerData.location = [];
          }
          if (!acc[indexOwner].ownerData.temperature) {
            acc[indexOwner].ownerData.temperature = [];
          }
          if (!acc[indexOwner].ownerData.weight) {
            acc[indexOwner].ownerData.weight = [];
          }
          if (obj.dataType === 'GeoJSON') {
            acc[indexOwner].ownerData.location.push(obj);
          } else if (obj.dataType === 'senML') {
            acc[indexOwner].ownerData[obj.data[0].n].push(obj);
          }
        }

        return acc;
      }, [])
    : null
);

// protocol data
// * Delete this function
export const selectProtocolsData = createSelector(selectAllData, (Data) =>
  Data
    ? Data.data.reduce((all, elem) => {
        const temp = {};
        if (elem.dataType === 'SFCKeyValue') {
          temp[elem.data[0].key] = elem.data[0].value;
        }

        return { ...all, ...temp };
      }, {})
    : {}
);

/**
 * This function gets all the data that is not GeoJSON weight temperature volume energy or file type
 * -Parse the dates type
 * -Parse true or false to Yes or No
 */
export const selectSingleData = createSelector(selectAllData, (addData) => {
  const arr = [];
  addData &&
    addData.data.forEach((data, i) => {
      if (
        data.dataType !== 'GeoJSON' &&
        data.dataType !== 'weight' &&
        data.dataType !== 'temperature' &&
        data.dataType !== 'volume' &&
        data.dataType !== 'energy' &&
        data.dataType !== 'file' &&
        data.dataType !== 'chat'
      ) {
        switch (data.dataType) {
          case 'date':
            const date = moment(data.data.value).format('DD.MM.YYYY, HH:mm');
            if (date === 'Invalid date') {
              arr.push({ key: data.data.key, value: data.data.value });
            } else {
              arr.push({ key: data.data.key, value: date });
            }
            break;
          case 'boolean':
            arr.push({
              key: data.data.key,
              value: data.data.value === true ? 'Yes' : 'No',
            });
            break;
          case 'SFCKeyValue':
            if (
              data.data[0].key === 'Harvest Date' ||
              data.data[0].key === 'Production Date' ||
              data.data[0].key === 'Use By Date'
            ) {
              arr.push({
                key: data.data[0].key,
                value: moment(data.data[0].value).format('DD.MM.YYYY'),
              });
            } else {
              arr.push({ key: data.data[0].key, value: data.data[0].value });
            }
            break;
          default:
            arr.push({
              key: data.data ? data.data.key : 'unknown',
              value: data.data ? data.data.value : 'unknown',
            });
            break;
        }
      }
    });
  return arr;
});

//Tags
export const selectTags = createSelector(selectAllData, (Classification) =>
  Classification ? Classification.classification.tags : []
);

export const selectGtin = createSelector(selectAllData, (Gtin) =>
  Gtin ? Gtin.gtin : ''
);

export const selectIdentifierGtin = createSelector(selectAllData, (Gtin) =>
  Gtin && Gtin.identifiers
    ? Gtin.identifiers.filter((idn) => '01' in idn).length > 0
      ? Gtin.identifiers.filter((idn) => '01' in idn)[0]['01']
      : ''
    : ''
);

export const selectIdentifierSerialNumber = createSelector(
  selectAllData,
  (SerNum) =>
    SerNum && SerNum.identifiers
      ? SerNum.identifiers.filter((idn) => '21' in idn).length > 0
        ? SerNum.identifiers.filter((idn) => '21' in idn)[0]['21']
        : ''
      : ''
);

export const selectRedirectUrl = createSelector(selectAllData, (ShareData) =>
  ShareData && ShareData.shareData ? ShareData.shareData.url : ''
);
//Description
export const selectDescription = createSelector(
  selectAllData,
  (Classification) =>
    Classification ? Classification.classification.description : null
);

//Documents
export const selectDocuments = createSelector(selectAllData, (bino) =>
  bino
    ? bino.data
        .filter((e) => e.dataType === 'file')
        .reduce((all, elem) => {
          const temp = {};
          temp['fileName'] = elem.data.metadata.fileName;
          temp['fileExtension'] = elem.data.metadata.fileExtension;
          temp['fileSize'] = elem.data.metadata.fileSize;
          temp['dataLink'] = elem.data.metadata.dataLink;
          temp['createdAt'] = elem.data.createdAt;
          all.push(temp);
          return all;
        }, [])
    : ''
);
