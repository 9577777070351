import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import ApiService from 'api/APIService';
import { selectTransaction } from '../../pages/data-page/selectors';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import validator from 'validator';
import { isObjectEmpty } from 'api/utils/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '120px 2fr',
    gridTemplateRows: '0.2fr 1fr',
    gridGap: '20px',
  },
  pic: {},
  name: {},
  singleDatas: {
    [theme.breakpoints.down('sm')]: {
      gridRow: '3 / 4',
      gridColumn: '1/ 3',
    },
    gridRow: '2 / 3',
    gridColumn: '2/ 3',
  },
  avatarSize: {
    [theme.breakpoints.down('sm')]: {
      height: '120px',
      width: '120px',
    },
    height: '160px',
    width: '160px',
    border: 'none',
    borderRadius: '20%',
  },
  nameSize: {},
  singleDatasSize: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function AttributesCard({
  photo,
  name,
  singleDetails,
  singleData,
  props,
  txid,
  gtin,
  serialNumber,
  identifier = {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const accessItemTransaction = useSelector(selectTransaction);

  const [isTxidFull, setIsTxidFull] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleDownloadProductCert = () => {
    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        itemId: accessItemTransaction,
        creatorName: 'tracking-page',
        documentTitle: `product-certificate`,
        reportTitle: `product-certificate`,
      };
      let responseData;

      try {
        responseData = await ApiService.productCertificate(
          reportParams,
          signal
        );
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(responseData);
        a.download = reportParams.documentTitle;
        a.click();
      } catch (e) {
        console.error('Error downloading package');
      }

      // const jobId = responseData.jobId;
      // try {
      //   const jobParams = { jobId };
      //   while (!signal.aborted && responseData.progress !== 100) {
      //     responseData = await ApiService.queryLatestJobStatus(
      //       jobParams,
      //       signal
      //     );
      //     if (responseData.status === ApiService.jobStatus.failed) {
      //       console.error("Error downloading package");
      //     }
      //     if (
      //       responseData.status === ApiService.jobStatus.done ||
      //       responseData.status === ApiService.jobStatus.failedBlockchain
      //     ) {
      //       await handlePreviewFile(undefined, responseData, signal);
      //     }
      //   }
      //   if (signal.aborted) {
      //     console.error("Operation aborted");
      //   }
      // } catch (e) {
      //   console.error("Error downloading package");
      // }
    };
    handlePrepareFileDownload();
  };

  const handleOpenLink = () => {
    window.open(identifier.data, '_blank', 'noreferrer');
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">{t('txid-copied-to-clipboard')}</Alert>
      </Snackbar>

      <div style={{ display: 'inline-flex' }}>
        <div style={{ marginRight: '20px' }}>
          {photo && (
            <img
              className={clsx(classes.pic, classes.avatarSize)}
              // style={{ height: '90px', width: '90px' }}
              src={photo}
              alt="img"
            ></img>
          )}
        </div>
        <div>
          <>
            <div className={clsx(classes.name, classes.nameSize)}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography variant="h2" style={{ wordBreak: 'break-word' }}>
                  {name}
                </Typography>
                <Tooltip
                  title={t('download-certificate')}
                  style={{ marginLeft: '10px' }}
                >
                  <IconButton color="info" onClick={handleDownloadProductCert}>
                    <GetAppIcon style={{ color: '#024266' }} />
                  </IconButton>
                </Tooltip>
                {!isObjectEmpty(identifier) &&
                  validator.isURL(identifier.data) && (
                    <Tooltip
                      title={t('external-product-page')}
                      style={{ marginLeft: '10px' }}
                    >
                      <IconButton color="info" onClick={handleOpenLink}>
                        <LinkIcon style={{ color: '#024266' }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              {txid && (
                <div>
                  <div
                    style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  >
                    <Typography variant="body2" style={{ marginRight: '15px' }}>
                      TXID
                    </Typography>
                    <Tooltip title={txid} style={{ cursor: 'pointer' }}>
                      <Typography
                        variant="subtitle1"
                        onClick={() => {
                          window.open(
                            `https://whatsonchain.com/tx/${txid}`,
                            '_blank'
                          );
                          setIsTxidFull(!isTxidFull);
                          // navigator.clipboard.writeText(txid);
                          // setOpenSnackbar(true);
                        }}
                        alt={txid}
                      >
                        {isTxidFull ? txid : txid?.substring(0, 5)}
                      </Typography>
                    </Tooltip>
                  </div>
                </div>
              )}
              {gtin && (
                <div>
                  <div
                    style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  >
                    <Typography variant="body2" style={{ marginRight: '15px' }}>
                      GTIN
                    </Typography>
                    <Typography variant="subtitle1">{gtin}</Typography>
                  </div>
                </div>
              )}
              {serialNumber && (
                <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                  <Typography variant="body2" style={{ marginRight: '15px' }}>
                    {t('serial-number')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ wordBreak: 'break-all' }}
                  >
                    {serialNumber}
                  </Typography>
                </div>
              )}
            </div>
          </>
        </div>
      </div>

      {/*  {matches ? (
        <LayoutMobile
          Element1={element1}
          Element2={element2}
          Element3={element3}
        />
      ) : (
        <LayoutWeb
          Element1={element1}
          Element2={element2}
          Element3={element3}
        />
      )} */}
    </>
  );
}
