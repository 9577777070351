import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AlertDialogSlide from '../../components/common/AlertDialogSlide';

import DTDescription from '../../components/common/DTDescription';
import ProductDNA from '../traceability-page/productDNA';
import LandingPageSkeleton from './LandingPageSkeleton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import MuiAlert from '@material-ui/lab/Alert';
import LeafletLocatioMap from 'component-factory/geoMap';
import DescriptionIcon from '@material-ui/icons/Description';
import SDCService from 'api/SDCService.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomizedTimeline from '../traceability-page/CustomizedTimeline';
import LightweightChart from 'component-factory/lightweightChart';
import AttributesCard from './AttributesCard';
import { useTranslation } from 'react-i18next';
import APIService from 'api/APIService';
import isEmail from 'validator/lib/isEmail';
import Chip from '@material-ui/core/Chip';
import DTSingleAttributes from '../../components/common/DTSingleAttributes';
//icons
import { Link } from 'react-router-dom';

import {
  selectNonExistenceItem,
  selectAccessabilityItem,
  selectDescription,
  // selectProtocol,
  selectProtocolsData,
  selectTags,
  selectDocuments,
  selectSingleData,
  selectTxid,
  selectGtin,
  selectIdentifierGtin,
  selectIdentifierSerialNumber,
  selectRedirectUrl,
} from '../../store/selectors';
import {
  selectTrackingPoints,
  selectTraceability,
  selectProductDNA,
  selectPointsOnMap,
  selectColorsCreators,
} from '../traceability-page/selectors';
import {
  selectAllEnergy,
  selectAllVolume,
  selectAllTemperatures,
  selectAllWeights,
  selectCardInfo,
  selectIdentifier,
  selectAllDataToFillTable,
} from '../data-page/selectors';
import { isObjectEmpty } from 'api/utils/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    width: '80%',
    alignSelf: 'center',
  },

  cardSection: {
    marginTop: 40,
    width: '100%',
  },
  firstSection: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    display: 'flex',
  },
  graphBg: {
    height: 140,
    paddingBottom: 20,
    //marginBottom: 20,
    backgroundColor: '#ffffff',
  },
  block: {
    padding: 20,
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  button: {
    backgroundColor: '#CFAF4E',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
}));

const LandingPage = (props) => {
  const { t } = useTranslation();
  const param = props.match.params.id;
  const param2 = props.match.params.id2;
  const param3 = props.match.params.id3;
  const param4 = props.match.params.id4;

  const classes = useStyles();
  //Selectors
  const itemNoExistence = useSelector(selectNonExistenceItem);
  const access = useSelector(selectAccessabilityItem);
  const cardInfo = useSelector(selectCardInfo);
  const txid = useSelector(selectTxid);
  const description = useSelector(selectDescription);
  const tags = useSelector(selectTags);
  const gtin = useSelector(selectGtin);
  const identifierGtin = useSelector(selectIdentifierGtin);
  const identifierSerialNumber = useSelector(selectIdentifierSerialNumber);
  const redirectUrl = useSelector(selectRedirectUrl);
  // const protocol = useSelector(selectProtocol);

  const protocolsData = useSelector(selectProtocolsData);
  const docs = useSelector(selectDocuments);
  const allWeights = useSelector(selectAllWeights);
  const allTemperatures = useSelector(selectAllTemperatures);
  const allEnergy = useSelector(selectAllEnergy);
  const allVolume = useSelector(selectAllVolume);
  const trackingPoints = useSelector(selectTrackingPoints);
  const traceability = useSelector(selectTraceability);
  const productDNA = useSelector(selectProductDNA);
  const pointsOnMap = useSelector(selectPointsOnMap);
  const colorsCreators = useSelector(selectColorsCreators);
  const singleData = useSelector(selectSingleData);
  const identifierData = useSelector(selectIdentifier);
  const selectAllData = useSelector(selectAllDataToFillTable);

  // const { reviews, reviewsError } = useSelector(state => ({
  //   reviews: state.mapReducer.reviews,
  //   reviewsError: state.mapReducer.reviewsError
  // }), shallowEqual);

  const [reviewerRating, setReviewerRating] = useState(null);
  const [reviewerEmail, setReviewerEmail] = useState('');
  const [reviewerMessage, setReviewerMessage] = useState('');
  const [reviewLoading, setReviewLoading] = useState(false);
  const [reviewSent, setReviewSent] = useState(false);
  const [reviewError, setReviewError] = useState(null);

  if (redirectUrl) {
    window.location.replace(redirectUrl);
  }
  //Single temperature values
  const singleTemperature = Object.keys(allTemperatures).reduce((all, elem) => {
    if (allTemperatures[elem].length === 1) {
      return { [elem]: allTemperatures[elem] };
    }
    return all;
  }, {});

  //Single weight values
  const singleWeight = Object.keys(allWeights).reduce((all, elem) => {
    if (allWeights[elem].length === 1) {
      return { [elem]: allWeights[elem] };
    }
    return all;
  }, {});

  //Single energy values
  const singleEnergy = Object.keys(allEnergy).reduce((all, elem) => {
    if (allEnergy[elem].length === 1) {
      return { [elem]: allEnergy[elem] };
    }
    return all;
  }, {});

  //Single volume values
  const singleVolume = Object.keys(allVolume).reduce((all, elem) => {
    if (allVolume[elem].length === 1) {
      return { [elem]: allVolume[elem] };
    }
    return all;
  }, {});

  //Dialog
  const [openDialog, setopenDialog] = useState(false);
  const [loading, setLoading] = React.useState(false);

  //Side Effects
  useEffect(() => {
    setopenDialog(access);
  }, [access]);

  const sendReviewHandler = async () => {
    try {
      setReviewLoading(true);
      await APIService.upsertPublicLookup({
        id: `review_${txid}`,
        type: 'REVIEW',
        addValues: [
          JSON.stringify({
            rating: reviewerRating,
            email: reviewerEmail,
            message: reviewerMessage,
            dateTime: new Date().toISOString(),
          }),
        ],
      });
      setReviewSent(true);
    } catch (error) {
      setReviewError(error);
    } finally {
      setReviewLoading(false);
    }
  };

  const handeReviewerEmailChange = (event) => {
    setReviewerEmail(event.target.value);
  };

  const handeReviewerMessageChange = (event) => {
    setReviewerMessage(event.target.value);
  };

  const SectionHeader = ({ title, sectionPath = '' }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {sectionPath.length > 0 && (
          <>
            {param === '01' && param3 === '21' && (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/${sectionPath}/${param}/${param2}/${param3}/${param4}`}
              >
                {' '}
                <Typography
                  variant="button"
                  style={{ marginTop: '15px', color: '#024266' }}
                >
                  {t('view')}
                </Typography>
              </Link>
            )}
            {param === '01' && param3 === undefined && (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/${sectionPath}/${param}/${param2}`}
              >
                {' '}
                <Typography
                  variant="button"
                  style={{ marginTop: '15px', color: '#024266' }}
                >
                  {t('view')}
                </Typography>
              </Link>
            )}
            {param !== '01' && (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/${sectionPath}/${param}${param2 ? '/' + param2 : ''}`}
              >
                {' '}
                <Typography
                  variant="button"
                  style={{ marginTop: '15px', color: '#024266' }}
                >
                  {t('view')}
                </Typography>
              </Link>
            )}
          </>
        )}
      </div>
    );
  };

  if (itemNoExistence.message) {
    return (
      <AlertDialogSlide
        isOpen={true}
        dialogTitle={itemNoExistence.message}
        buttonTitle={`${t('take-me-to')} Inlandsbryggeri`}
        redirect={() =>
          window.location.assign('https://inlandsbryggeri.my.canva.site')
        }
      >
        {t('this-link-has-expired-or-been-removed')}.
      </AlertDialogSlide>
    );
  }

  const singleAttributes = {
    production: protocolsData?.['Production Date'],
    origin: protocolsData['Catch Area'],
    producer: protocolsData['Producer Name'],
    useBy: protocolsData['Use By Date'],
    conditionalInfo: 'protocol',
    singleTemperature: singleTemperature,
    singleWeight: singleWeight,
    singleVolume: singleVolume,
    singleEnergy: singleEnergy,
    singleData: singleData,
  };

  const renderDna =
    productDNA && productDNA.nodes && productDNA.nodes.length > 0;

  const body = (
    <>
      <div className={classes.DTAttributes} style={{ marginTop: '35px' }}>
        <DTDescription desc={description} />
      </div>
      <Typography
        variant="h6"
        style={{ marginTop: '35px', marginBottom: '10px' }}
      >
        {t('tags')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ marginBottom: '2px' }}>
          {tags.map((tag, index) => {
            return (
              <Chip
                key={index}
                label={tag}
                variant="outlined"
                style={{
                  marginRight: '5px',
                  marginTop: '2px',
                  color: '#024266',
                  border: '1px solid #024266',
                }}
              />
            );
          })}
        </Grid>
      </Grid>

      {(param !== '01' || (param === '01' && param3 === '21')) && (
        <>
          {docs.length > 0 && (
            <>
              <Typography
                variant="h6"
                style={{ marginTop: '35px', marginBottom: '10px' }}
              >
                {t('data')}
              </Typography>
              <DTSingleAttributes
                singleDetails={singleAttributes}
                singleData={singleData}
                props={props}
              />
              <Typography
                variant="h6"
                style={{ marginTop: '35px', marginBottom: '10px' }}
              >
                {t('attachments')}
              </Typography>
              {docs.map((file, i) => {
                return (
                  <Button
                    key={i}
                    fullWidth
                    startIcon={
                      i === loading ? (
                        <CircularProgress
                          style={{ color: '#024266' }}
                          size={20}
                        />
                      ) : (
                        <DescriptionIcon style={{ color: '#024266' }} />
                      )
                    }
                    style={{ justifyContent: 'flex-start', color: '#024266' }}
                    onClick={() => {
                      setLoading(i);
                      SDCService.downloadPackageAsync(file.dataLink).then(
                        () => {
                          setLoading(false);
                        }
                      );
                    }}
                  >
                    {file.fileName}
                  </Button>
                );
              })}
            </>
          )}

          <Grid
            container
            direction="row"
            spacing={4}
            style={{ marginTop: '20px' }}
          >
            {renderDna && (
              <Grid item xs={12} sm={12} md={6}>
                <SectionHeader title={t('twin-dna')} sectionPath="dna" />
                <Paper
                  style={{
                    height: 300,
                    overflow: 'hidden',
                    borderRadius: '10px',
                  }}
                  elevation={0}
                >
                  <ProductDNA showInfoBox={false} data={productDNA} />
                </Paper>
              </Grid>
            )}

            {isObjectEmpty(allTemperatures) &&
              isObjectEmpty(allWeights) &&
              isObjectEmpty(allEnergy) &&
              isObjectEmpty(allVolume) &&
              !isObjectEmpty(selectAllData) && (
                <Grid xs={12}>
                  <Grid xs={3} key="all-data" style={{ marginLeft: '15px' }}>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        <SectionHeader
                          title={t('data')}
                          sectionPath="InfoPage"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            <Grid item xs={12} sm={12} md={6}>
              <SectionHeader title={t('track-and-trace')} sectionPath="trace" />
              <Paper
                style={{ height: 300, borderRadius: '10px' }}
                elevation={0}
              >
                <LeafletLocatioMap
                  height="300px"
                  points={[pointsOnMap]}
                  ownerColors={colorsCreators}
                  polylines={trackingPoints}
                />
              </Paper>
            </Grid>
            {/* transactions */}
            {traceability.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <SectionHeader title={t('transfers')} sectionPath="transfers" />
                <Paper
                  style={{
                    height: 300,
                    overflow: 'auto',
                    borderRadius: '10px',
                  }}
                  elevation={0}
                >
                  <CustomizedTimeline transfers={traceability} />
                </Paper>
              </Grid>
            )}

            {(!isObjectEmpty(allTemperatures) ||
              !isObjectEmpty(allWeights) ||
              !isObjectEmpty(allEnergy) ||
              !isObjectEmpty(allVolume)) && (
              <Grid item md={6} xs={12} key="data">
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <SectionHeader title={t('data')} sectionPath="InfoPage" />
                  </Grid>
                  <Grid item xs={12} className={classes.block}>
                    <Grid container spacing={2}>
                      {!isObjectEmpty(allTemperatures) && (
                        <Grid item md={6} xs={12} className={classes.graphBg}>
                          <Typography variant="subtitle1" gutterBottom>
                            {t('temperature')}
                          </Typography>
                          {Object.keys(allTemperatures).map((key, i) => (
                            <LightweightChart
                              key={i}
                              area={allTemperatures[key]}
                              topColor={
                                allTemperatures[key][0].metadata.chartColor
                              }
                              lineColor={
                                allTemperatures[key][0].metadata.chartColor
                              }
                              legend={allTemperatures[key][0].metadata.unit}
                            />
                          ))}
                        </Grid>
                      )}
                      {!isObjectEmpty(allWeights) && (
                        <Grid item md={6} xs={12} className={classes.graphBg}>
                          <Typography variant="subtitle1" gutterBottom>
                            {t('weight')}
                          </Typography>
                          {Object.keys(allWeights).map((key, i) => (
                            <LightweightChart
                              key={i}
                              area={allWeights[key]}
                              topColor={allWeights[key][0].metadata.chartColor}
                              lineColor={allWeights[key][0].metadata.chartColor}
                              legend={allWeights[key][0].metadata.unit}
                            />
                          ))}
                        </Grid>
                      )}
                      {!isObjectEmpty(allEnergy) && (
                        <Grid item md={6} xs={12} className={classes.graphBg}>
                          <Typography variant="subtitle1" gutterBottom>
                            {t('energy')}
                          </Typography>
                          {Object.keys(allEnergy).map((key, i) => (
                            <LightweightChart
                              key={i}
                              area={allEnergy[key]}
                              topColor={allEnergy[key][0].metadata.chartColor}
                              lineColor={allEnergy[key][0].metadata.chartColor}
                              legend={allEnergy[key][0].metadata.unit}
                            />
                          ))}
                        </Grid>
                      )}
                      {!isObjectEmpty(allVolume) && (
                        <Grid item md={6} xs={12} className={classes.graphBg}>
                          <Typography variant="subtitle1" gutterBottom>
                            {t('volume')}
                          </Typography>
                          {Object.keys(allVolume).map((key, i) => (
                            <LightweightChart
                              key={i}
                              area={allVolume[key]}
                              topColor={allVolume[key][0].metadata.chartColor}
                              lineColor={allVolume[key][0].metadata.chartColor}
                              legend={allVolume[key][0].metadata.unit}
                            />
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <div style={{ marginTop: 35, marginBottom: 15 }}></div>
          <Grid item xs={12} sm={8} md={4}>
            {reviewError && (
              <MuiAlert severity="error">{reviewError.toString()}</MuiAlert>
            )}
            {reviewSent && (
              <MuiAlert severity="success">
                {t('thank-you-for-the-review')}
              </MuiAlert>
            )}
            {!reviewSent && (
              <>
                <SectionHeader title={t('item-rating')} />
                {t('please-rate-the-item')}
                <Box
                  component="div"
                  borderColor="transparent"
                  style={{ marginBottom: 15 }}
                >
                  <Rating
                    name="product-rating"
                    value={reviewerRating}
                    onChange={(event, rating) => {
                      setReviewerRating(rating);
                    }}
                  />
                </Box>
                {t('email')}
                <Box component="div" style={{ marginBottom: 15 }}>
                  <TextField
                    required
                    id="email"
                    variant="standard"
                    size="small"
                    fullWidth
                    inputProps={{
                      style: {
                        fontSize: 15,
                        backgroundColor: '#ffffff',
                        padding: 15,
                      },
                    }}
                    onChange={handeReviewerEmailChange}
                  />
                </Box>
                {t('message')}
                <Box component="div" style={{ marginBottom: 15 }}>
                  <TextField
                    required
                    id="message"
                    variant="standard"
                    multiline
                    minRows={3}
                    fullWidth
                    inputProps={{
                      style: {
                        fontSize: 15,
                        backgroundColor: '#ffffff',
                        padding: 15,
                      },
                    }}
                    onChange={handeReviewerMessageChange}
                  />
                </Box>
                <Button
                  variant="contained"
                  className={classes.button}
                  //style={{ backgroundColor: '#CFAF4E' }}
                  onClick={sendReviewHandler}
                  disabled={
                    !reviewerRating ||
                    !reviewerEmail ||
                    !isEmail(reviewerEmail) ||
                    !reviewerMessage ||
                    reviewLoading
                  }
                >
                  {reviewLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>{t('send')}</>
                  )}
                </Button>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );

  return (
    <>
      <LandingPageSkeleton loading={cardInfo.name} />
      <div className={classes.container}>
        {/* Sub section */}
        <div className={classes.firstSection}>
          <div className={classes.cardSection}>
            <AttributesCard
              photo={cardInfo.urlImage}
              name={cardInfo.name}
              singleDetails={singleAttributes}
              singleData={singleData}
              props={props}
              txid={txid}
              gtin={
                param === '01' && param3 === undefined ? gtin : identifierGtin
              }
              serialNumber={identifierSerialNumber}
              identifier={identifierData.length > 0 ? identifierData[0] : {}}
            />
          </div>
        </div>

        {body}
      </div>

      <AlertDialogSlide
        isOpen={openDialog}
        setOpen={setopenDialog}
        dialogTitle={t('this-link-is-private')}
        buttonTitle={t('i-understand')}
      >
        {t(
          'please-keep-this-information-confidential-and-only-discuss-it-directly-with-people-you-know-are-allowed-access'
        )}
      </AlertDialogSlide>
    </>
  );
};

export default LandingPage;
