import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
//components
import Contour from '../components/common/Contour';
import LeafletLocatioMap from 'component-factory/geoMap';
//icons
import ArrowBack from '@material-ui/icons/ArrowBack';
//Redux
import { useSelector } from 'react-redux';
//Selectors
import {
  selectPointsOnMap,
  selectColorsCreators,
  selectTrackingPoints,
} from './traceability-page/selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  map: {
    padding: 40,
  },
  arrowBack: {
    color: '#024266',
    marginTop: '20px',
    marginLeft: '40px',
  },
}));

export default function TrackPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const pointsOnMap = useSelector(selectPointsOnMap);
  const colorsCreators = useSelector(selectColorsCreators);
  const trackPoints = useSelector(selectTrackingPoints);

  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <>
      <Contour
        title={t('track-and-trace')}
        color={theme.palette.secondary.light}
      />
      <ArrowBack
        className={classes.arrowBack}
        fontSize="large"
        onClick={back}
      />
      <div className={classes.map}>
        <LeafletLocatioMap
          points={[pointsOnMap]}
          ownerColors={colorsCreators}
          polylines={trackPoints}
        />
      </div>
    </>
  );
}
