import React from 'react';
import { AFrameRenderer, Marker } from 'react-web-ar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const appBody = document.getElementsByTagName('body')[0];

export default function Hiro({ desc }) {
  const {
    displayName,
    paymailHandle,
    urlImage,
    transfers = [],
    dates = [],
    expired,
  } = desc;
  const { t } = useTranslation();

  const controlDates = JSON.stringify(
    dates.map((a) => `${a.key}: ${moment(a.value).format('Do MMMM YYYY')}`)
  )
    .slice(2, -2)
    .replace(',', '\n')
    .replace(/"/g, '');

  React.useEffect(() => {
    //Using A-frame, the only way to take of the scroll
    appBody.style.overflow = 'hidden';
    appBody.style.margin = '0px';
    appBody.style.position = 'fixed';
  }, []);

  return (
    <AFrameRenderer arToolKit={{ sourceType: 'webcam' }}>
      <Marker
        parameters={{
          type: 'pattern',
          patternUrl:
            'https://raw.githubusercontent.com/DavidNogueira/Create-a-dot-patt/master/pattern/novoUNI.patt',
          url: 'https://raw.githubusercontent.com/DavidNogueira/Create-a-dot-patt/master/pattern/novoUNI.patt',
        }}
      >
        <a-plane
          color="white"
          rotation="-90 0 0"
          position="0 -0.25 0"
          width="2.5"
          height="2.5"
          material="transparent: true; opacity: 0.90"
          geometry=""
        ></a-plane>
        <a-image
          position="0 -0.20 -1"
          rotation="-90 0 0"
          src={urlImage}
        ></a-image>
        <a-text
          color="black"
          rotation="-90 0 0"
          scale="0.8 0.8 0.8"
          value={`${displayName}\n${t('creator')}:${paymailHandle}\n${t(
            'transfers'
          )}:${transfers.length}\n`}
          align="center"
        ></a-text>

        {!expired ? (
          <a-text
            position="0 0 0.7"
            scale="0.6 0.6 0.6"
            rotation="-90 0 0"
            color="green"
            align="center"
            value={`${controlDates}`}
          ></a-text>
        ) : (
          <a-text
            position="0 0 0.7"
            scale="0.6 0.6 0.6"
            rotation="-90 0 0"
            color="red"
            align="center"
            value={`${controlDates}`}
          ></a-text>
        )}
      </Marker>
    </AFrameRenderer>
  );
}
