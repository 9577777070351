import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import theme from '../../theme';
//images
import norwayMap from '../../assets/norway_1.png';
//Components
import Contour from '../../components/common/Contour';
import HistoryCard from './HistoryCard';
//icons
import ArrowBack from '@material-ui/icons/ArrowBack';

const BlackTextTypography = withStyles({
  root: {
    color: '#000',
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bgImage: {
    height: 500,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  box: {
    width: '349px',
    height: '450px',
    zIndex: 1,
  },
  boxCard: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
    display: 'flex',
    margin: 68,
  },
  twoImagesBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 650,
      paddingBottom: '710px',
    },
    [theme.breakpoints.down('xs')]: {
      height: 900,
    },
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 500,
    textAlign: 'center',
  },
  middleText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  arrowBack: {
    color: '#024266',
    marginTop: '20px',
    marginLeft: '40px',
  },
}));

export default function HistoryPage(props) {
  const history = useHistory();
  const classes = useStyles();

  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <>
      <Contour title="History" color={theme.palette.secondary.dark} />
      <ArrowBack
        fontSize="large"
        className={classes.arrowBack}
        onClick={back}
      />

      {/**image */}
      <div
        style={{
          backgroundImage:
            'url(https://sterlingwhitehalibut.com/imager/assets/img/production/stamfisk_7fadff60d0c6dda806e0bf0ae0c1f3db.webp)',
          width: '100%',
        }}
        className={classes.bgImage}
      >
        <Fade timeout={1000} in>
          <Box
            className={classes.boxCard}
            style={{ justifyContent: 'flex-end' }}
          >
            <Box p={1} bgcolor="#eee" className={classes.box}>
              <HistoryCard
                title="Broodstock"
                paragraph1="The broodstock is the jewel here at Sterling! We take great care of these little ones, and strive to give them the best possible care and supervision."
                paragraph2="When the fish is ready for spawning, eggs and sperm are released through manual stroking. One female can yield up to 40 - 50 litres of eggs per season. SWH produces eggs throughout the year."
                textColor="#000"
              />
            </Box>
          </Box>
        </Fade>
      </div>

      {/**image */}
      <div
        className={classes.bgImage}
        style={{
          backgroundImage:
            'url(https://sterlingwhitehalibut.com/imager/assets/img/production/inkubator_7fadff60d0c6dda806e0bf0ae0c1f3db.webp)',
        }}
      >
        <Box
          className={classes.boxCard}
          style={{ justifyContent: 'flex-start' }}
        >
          <Box p={1} bgcolor="#333" className={classes.box}>
            <HistoryCard
              title="Hatchery / Nursery"
              paragraph1="As soon as the eggs are fertilised, they are transferred to our hatchery. Here, the embryos will be reared and monitored closely until they hatch, 10 - 12 days later."
              paragraph2="From each embryo comes a halibut larvae with a gigantic egg yolk attached. All the larvae will now spend the next 42 days in complete darkness in the nursery, while they grow and digest their yolk."
              textColor="white"
            />
          </Box>
        </Box>
      </div>

      {/**NO ONE IMAGE BUT TWO */}
      <Box className={classes.twoImagesBox}>
        <img
          src={
            'https://sterlingwhitehalibut.com/imager/assets/img/production/foring_7fadff60d0c6dda806e0bf0ae0c1f3db.webp'
          }
          alt="sterlingimg1"
          style={{
            margin: 30,
            height: 200,
            width: 200,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
            alignSelf: 'flex-end',
          }}
        />
        <div className={classes.middleText}>
          <Typography variant="h1">Feeding & Growth</Typography>
          <div style={{ color: 'black' }}>
            Halibut is fed with live prey - a zooplankton called Artemia. From
            0.5 grams, and up to five grams, the fish will feed on normal dry
            food.. In total, it takes seven months from the fertilisation of the
            eggs, until the halibut arrive at Reipholmen as juvenile fish.
          </div>
        </div>
        <img
          src={
            'https://sterlingwhitehalibut.com/imager/assets/img/production/paavekst_7fadff60d0c6dda806e0bf0ae0c1f3db.webp'
          }
          alt="sterlingimg2"
          style={{
            margin: 30,
            height: 200,
            width: 200,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        />
      </Box>

      {/**image with text/no card */}
      <div
        className={classes.bgImage}
        style={{
          display: 'flex',
          backgroundImage: 'url(' + norwayMap + ')',
        }}
      >
        <div className={classes.middleText}>
          <BlackTextTypography variant="h1">
            Imsland & Helland
          </BlackTextTypography>
          <div
            style={{
              color: 'black',
              textAlign: 'center',
              marginLeft: '20%',
              marginRight: '20%',
            }}
          >
            At Imsland growth facility the fish is bred from five grams, up
            until they reach 350 grams. Helland growth facility is the last stop
            before the fish move out to sea. Here, we breed the halibut from 350
            grams, up to 1200 - 1500 grams.
          </div>
        </div>
      </div>

      {/**image */}
      <div
        className={classes.bgImage}
        style={{
          backgroundImage:
            'url(https://sterlingwhitehalibut.com/imager/assets/img/production/sjoanlegg_7fadff60d0c6dda806e0bf0ae0c1f3db.webp)',
        }}
      >
        <Box
          className={classes.boxCard}
          style={{ justifyContent: 'flex-start' }}
        >
          <Box p={1} bgcolor="#333" className={classes.box}>
            <HistoryCard
              title="Out at sea"
              paragraph1="When the halibut reach 1500 grams of weight, we transport them by well boat out to our sea facilities in Vassvik and Kjeurda. They will stay here for 2 - 2.5 years, until they reach 4 - 5 kilos and are ready to be harvested."
              paragraph2="Out at sea, the fish stay in spacious cages, with shelves for them to rest on. They are continuously fed and cared for."
              textColor="white"
            />
          </Box>
        </Box>
      </div>

      {/**image */}
      <div
        className={classes.bgImage}
        style={{
          backgroundImage:
            'url(https://sterlingwhitehalibut.com/imager/assets/img/production/bloggestasjon_7fadff60d0c6dda806e0bf0ae0c1f3db.webp)',
        }}
      >
        <Box className={classes.boxCard} style={{ justifyContent: 'flex-end' }}>
          <Box p={1} bgcolor="#fff" className={classes.box}>
            <HistoryCard
              title="Harvest"
              paragraph1=" Once ready, the fully grown halibut will be loaded onto the well boat, and transported back on shore. "
              paragraph2="Here, the fish will be loaded onto the facility, stunned and bled. The bled fish is immediately cooled and transferred to the Fonn Butchery in Egersund, Norway. Here, the freshly harvested halibut is butchered and packaged, before it goes out to clients all over the world."
              textColor="#000"
            />
          </Box>
        </Box>
      </div>
      <div style={{ height: 100 }}></div>
    </>
  );
}
