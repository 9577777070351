import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  showMore: {
    cursor: 'pointer',
    fontSize: '0.75rem',
    textAlign: 'right',
  },
  gridMargin: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
    },
  },
  singleData: {
    alignItems: 'baseline',
    display: 'flex',
    marginRight: '20px',
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      // '& h6': {
      //   color: 'red',
      // },
    },
  },
  textVariant: {
    variant: theme.palette.text.subtitle1,
  },
}));

export default function DTSingleAttributes({ singleDetails, props }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    singleTemperature,
    singleWeight,
    singleVolume,
    singleEnergy,
    singleData,
  } = singleDetails;
  const param = props.match.params.id;
  const param2 = props.match.params.id2;
  const param3 = props.match.params.id3;
  const param4 = props.match.params.id4;

  const [singleSensors, setSingleSensors] = React.useState(undefined);
  // const [singleData, setSingleData] = React.useState(singleData)

  React.useEffect(() => {
    setSingleSensors({
      ...singleTemperature,
      ...singleWeight,
      ...singleVolume,
      ...singleEnergy,
      ...singleData,
    });
  }, [singleTemperature, singleWeight, singleVolume, singleEnergy, singleData]);

  const matches = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <div className={classes.root}>
      <Grid container spacing={0} style={{ marginBottom: '10px' }}>
        {/* Render all the single data */}
        {singleData?.length > 0 &&
          singleData
            .filter((data) => data.key !== undefined)
            .map((data, i) => {
              return (
                i < (matches ? 4 : 6) && (
                  <Grid key={`single-chart${i}`} item xs={6} sm={6} md={4}>
                    <div className={classes.singleData}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: '15px' }}
                      >{`${data.key}:`}</Typography>
                      <Typography variant="subtitle1">{data.value}</Typography>
                    </div>
                  </Grid>
                )
              );
            })}

        {singleSensors && Object.keys(singleSensors).length > 0 && (
          <>
            {param === '01' && param3 === '21' && (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/InfoPage/${param}/${param2}/${param3}/${param4}`}
              >
                <Typography variant="button" style={{ color: '#024266' }}>
                  {t('show-more')}
                </Typography>
              </Link>
            )}
            {param === '01' && param3 === undefined && (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/InfoPage/${param}/${param2}`}
              >
                <Typography
                  variant="button"
                  style={{ marginTop: '15px', color: '#024266' }}
                >
                  {t('show-more')}
                </Typography>
              </Link>
            )}
            {param !== '01' && (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/InfoPage/${param}${param2 ? '/' + param2 : ''}`}
              >
                <Typography
                  variant="button"
                  color="secondary"
                  style={{ marginTop: '15px', color: '#024266' }}
                >
                  {t('show-more')}
                </Typography>
              </Link>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}

DTSingleAttributes.propTypes = {
  production: propTypes.string,
  origin: propTypes.string,
  useBy: propTypes.string,
  producer: propTypes.string,
  conditionalInfo: propTypes.string,
  singleTemperature: propTypes.object,
  singleWeight: propTypes.object,
  singleVolume: propTypes.object,
  singleEnergy: propTypes.object,
};
