import React from 'react';
import PageLayout from './layouts/PageLayout';
import HomePageLayout from './layouts/HomePageLayout';
import CssBaseline from '@material-ui/core/CssBaseline';

const Layout = ({ children }) => (
  <PageLayout>
    <CssBaseline />
    {children}
  </PageLayout>
);

const NoLayout = ({ children }) => (
  <div style={{ margin: 0 }}>
    <CssBaseline />
    {children}
  </div>
);

const ErrorLayout = ({ children }) => (
  <div style={{ margin: 0 }}>
    <CssBaseline />
    {children}
  </div>
);

const HomeLayout = ({ children }) => (
  <HomePageLayout>
    <CssBaseline />
    {children}
  </HomePageLayout>
);

export { Layout, NoLayout, HomeLayout, ErrorLayout };
