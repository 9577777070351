import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import AlertDialogSlide from './AlertDialogSlide';

function IdleTimerComponent() {
  const idleTimerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const onIdle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={600 * 1000} //10 minutes
        onIdle={onIdle}
      ></IdleTimer>
      <AlertDialogSlide
        isOpen={open}
        setOpen={setOpen}
        dialogTitle="We noticed that you have been away for a while."
        buttonTitle="OK"
      >
        Please keep this information confidential and only discuss it directly
        with people you know are allowed access.
      </AlertDialogSlide>
    </div>
  );
}

export default IdleTimerComponent;
