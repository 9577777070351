import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function HistoryCard({
  title,
  paragraph1,
  paragraph2,
  textColor,
}) {
  return (
    <Grid container direction="row" spacing={1} style={{ padding: 20 }}>
      <Grid style={{ width: '100%' }} item md={12}>
        <Typography
          variant="h1"
          style={{
            color: textColor,
            fontWeight: 800,
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item md={12}>
        <p style={{ color: textColor }}>{paragraph1}</p>
      </Grid>
      <Grid item md={12}>
        <p style={{ color: textColor }}>{paragraph2}</p>
      </Grid>
    </Grid>
  );
}
