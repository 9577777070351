import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

//reducers
import trackingReducer from 'api/tracking/reducers';

//sagas
import { trackingSaga } from 'api/tracking/sagas';

export const rootReducer = combineReducers({
  mapReducer: trackingReducer,
});

export function* rootSaga() {
  yield all([...trackingSaga]);
}
