import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  cards: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 4,
      alignItems: 'center',
      flexDirection: 'column',
    },
    display: 'flex',
    justifyContent: 'space-around ',
  },
  name: {
    alignSelf: 'center',
  },
  image: {
    border: 'none',
    position: 'relative',
    marginBottom: '0px',
    height: '100vh',
    backgroundImage: `url(https://i.imgur.com/KTQ0AVR.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    opacity: '1',
    display: 'flex',
    justifyContent: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '200px',
    alignContent: 'center',
    marginLeft: '20%',
  },
}));
const ErrorPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.image}>
      <div className={classes.errorContainer}>
        <Typography
          variant="h1"
          color="primary"
          style={{ marginBottom: '20px' }}
        >
          Oops!
        </Typography>
        <Typography variant="h2" color="primary">
          {t('where-are-we')}
        </Typography>
        <div style={{ width: '70%' }}>
          <Typography variant="h4" color="primary">
            {t(
              'the-page-you-are-looking-for-has-been-moved-removed-renamed-or-may-never-have-existed'
            )}
          </Typography>
          <div style={{ marginTop: '20px' }}>
            <Link style={{ textDecoration: 'none' }} to={`/home`}>
              <Button variant="contained" color="secondary">
                <Typography variant="h4" color="primary">
                  {t('go-home')}
                </Typography>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
