const tokenStorage = {
  cookie: 'COOKIE',
  localStorage: 'LOCAL_STORAGE',
  mobileStorage: 'MOBILE_STORAGE',
};

let tokenStorageType = tokenStorage.cookie;

const getEnvStorage = async () => {
  return null;
};

const setTokenStorageType = (storageType) => {
  tokenStorageType = storageType;
};

const setSessionToken = async () => {
  return null;
};

const getSessionToken = () => {
  return null;
};

const clearSessionToken = () => {
  return null;
};

const ApiStorage = {
  getEnvStorage,
  tokenStorageType,
  setTokenStorageType,
  setSessionToken,
  getSessionToken,
  clearSessionToken,
};

export default ApiStorage;
