import React from 'react';
import { useHistory } from 'react-router-dom';
import { isObjectEmpty } from 'api/utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import theme from './../theme';
//Components
import Contour from '../components/common/Contour';
//icons
import ArrowBack from '@material-ui/icons/ArrowBack';
//Redux
import { useSelector } from 'react-redux';
//Selectors
import { selectProductDNA } from './traceability-page/selectors';
import ProductDNA from './traceability-page/productDNA';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  arrowBack: {
    color: '#024266',
    marginTop: '20px',
    marginLeft: '40px',
  },
}));

export default function DnaPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const productDNA = useSelector(selectProductDNA);
  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <>
      <Contour title={t('twin-dna')} color={theme.palette.secondary.light} />
      <ArrowBack
        fontSize="large"
        className={classes.arrowBack}
        onClick={back}
      />
      <div style={{ margin: '40px' }}>
        {!isObjectEmpty(productDNA) && <ProductDNA data={productDNA} />}
      </div>
    </>
  );
}
