import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SDCService from 'api/SDCService.js';
import theme from '../../theme';
//icons
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowBack from '@material-ui/icons/ArrowBack';
//Redux
import { useSelector } from 'react-redux';
//Components
import LightweightChart from 'component-factory/lightweightChart';
import Contour from '../../components/common/Contour';
import TableMap from '../../components/common/DetailsTable/TableMap';
//Selectors
import {
  selectAllDataToFillTable,
  selectAllExpandedData,
  selectAllEnergy,
  selectAllVolume,
  selectAllTemperatures,
  selectAllWeights,
} from '../data-page/selectors';

import { selectDocuments } from '../../store/selectors';
import { isObjectEmpty } from 'api/utils/utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    padding: 20,
  },
  block: {
    marginBottom: 20,
    padding: 15,
    borderRadius: 10,
  },
  graphBg: {
    height: 300,
    padding: 20,
    marginBottom: 20,
  },
  title: {
    marginBottom: 10,
  },
  arrowBack: {
    color: '#024266',
  },
}));
export default function InfoPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const docs = useSelector(selectDocuments);
  //Selectors
  const allDataToFillTable = useSelector(selectAllDataToFillTable);
  const allExpandedData = useSelector(selectAllExpandedData);
  const allTemperatures = useSelector(selectAllTemperatures);
  const allEnergy = useSelector(selectAllEnergy);
  const allWeights = useSelector(selectAllWeights);
  const allVolume = useSelector(selectAllVolume);

  const [loading, setLoading] = React.useState(false);

  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <>
      <Contour title={t('info')} color={theme.palette.primary.light} />
      <div className={classes.root}>
        <ArrowBack
          fontSize="large"
          className={classes.arrowBack}
          onClick={back}
        />
        <Container>
          <Grid container spacing={3}>
            <Grid item md={isObjectEmpty(allTemperatures) ? 12 : 6} xs={12}>
              {docs.length > 0 && (
                <Grid item xs={12} style={{ marginBottom: '25px' }}>
                  <Typography variant="h6" style={{ marginBottom: '15px' }}>
                    {t('images-and-attachments')}
                  </Typography>
                  <div>
                    {docs.map((file, i) => {
                      return (
                        <Button
                          key={i}
                          fullWidth
                          startIcon={
                            i === loading ? (
                              <CircularProgress
                                style={{ color: '#024266' }}
                                size={20}
                              />
                            ) : (
                              <DescriptionIcon style={{ color: '#024266' }} />
                            )
                          }
                          style={{
                            justifyContent: 'flex-start',
                            color: '#024266',
                          }}
                          onClick={() => {
                            setLoading(i);
                            SDCService.downloadPackageAsync(file.dataLink).then(
                              () => {
                                setLoading(false);
                              }
                            );
                          }}
                        >
                          {file.fileName}
                        </Button>
                      );
                    })}
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" style={{ marginBottom: '15px' }}>
                  {t('all-data-points')}
                </Typography>
                <TableMap
                  data={allDataToFillTable}
                  expandedData={allExpandedData}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              {!isObjectEmpty(allTemperatures) && (
                <>
                  <Typography variant="h6" style={{ marginBottom: '15px' }}>
                    {t('temperature')}
                  </Typography>
                  {Object.keys(allTemperatures).map((key, i) => {
                    return (
                      <Paper className={classes.graphBg} key={i}>
                        <LightweightChart
                          area={allTemperatures[key]}
                          topColor={allTemperatures[key][0].metadata.chartColor}
                          lineColor={
                            allTemperatures[key][0].metadata.chartColor
                          }
                          legend={allTemperatures[key][0].metadata.unit}
                        />
                      </Paper>
                    );
                  })}
                </>
              )}

              {!isObjectEmpty(allWeights) && (
                <>
                  <Typography
                    variant="h6"
                    style={{ marginBottom: '15px', paddingTop: '20px' }}
                  >
                    {t('weight')}
                  </Typography>
                  {Object.keys(allWeights).map((key, i) => {
                    return (
                      <Paper className={classes.graphBg}>
                        <LightweightChart
                          area={allWeights[key]}
                          legend={allWeights[key][0].metadata.unit}
                        />
                      </Paper>
                    );
                  })}
                </>
              )}

              {!isObjectEmpty(allEnergy) && (
                <>
                  <Typography
                    variant="h6"
                    style={{ marginBottom: '15px', paddingTop: '20px' }}
                  >
                    {t('energy')}
                  </Typography>
                  {Object.keys(allEnergy).map((key, i) => {
                    return (
                      <Paper className={classes.graphBg} key={i}>
                        <LightweightChart
                          area={allEnergy[key]}
                          legend={allEnergy[key][0].metadata.unit}
                        />
                      </Paper>
                    );
                  })}
                </>
              )}

              {!isObjectEmpty(allVolume) && (
                <>
                  <Typography
                    variant="h6"
                    style={{ marginBottom: '15px', paddingTop: '20px' }}
                  >
                    {t('volume')}
                  </Typography>
                  {Object.keys(allVolume).map((key, i) => {
                    return (
                      <Paper className={classes.graphBg}>
                        <LightweightChart
                          area={allVolume[key]}
                          legend={allVolume[key][0].metadata.unit}
                        />
                      </Paper>
                    );
                  })}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
