import React from 'react';
import propTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { VisibilityOff } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide elevation={25} direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  children,
  dialogTitle,
  isOpen,
  setOpen,
  buttonTitle,
  redirect,
  icon = (
    <VisibilityOff
      style={{
        display: 'inlineFlex',
        verticalAlign: 'sub',
        marginRight: '15px',
        color: '#024266',
      }}
    />
  ),
}) {
  const handleClose = () => {
    setOpen(!isOpen);
  };
  const handleRedirect = () => {
    redirect();
  };

  return (
    <div>
      <Dialog
        PaperProps={{ elevation: 0 }}
        open={isOpen || false}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {icon}
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: '#04182B' }}
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={redirect ? handleRedirect : handleClose}
            style={{ color: '#024266' }}
          >
            {buttonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialogSlide.propTypes = {
  children: propTypes.string,
  dialogTitle: propTypes.string,
  isOpen: propTypes.bool,
  setOpen: propTypes.func,
  buttonTitle: propTypes.string,
  redirect: propTypes.string,
  icon: propTypes.element,
};
