import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function Loading() {
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
    },
    load: {
      marginTop: '30vh',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        size={70}
        thickness={3}
        className={classes.load}
        disableShrink
      />
    </div>
  );
}
