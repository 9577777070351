import { createTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';

//WIP
const theme = createTheme({
  palette: {
    common: {
      black: '#04172c',
      white: '#e6e6e6',
    },
    primary: {
      light: '#A6BDCB', //info
      main: '#F1F4F6', //background
      // dark: '#121221',
      dark: '#017F98', //documents
      contrastText: '#fff',
    },
    secondary: {
      light: '#019062', //trace
      main: '#1973DC', //data
      dark: '#062B51', //history
      contrastText: '#04182B',
    },
    success: {
      main: '#0e9902',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sansSerif',
    // body2:{
    //   color: '#ee0',
    //   fontSize: '1.4rem',
    //   fontWeight:800,
    // },
    h1: {
      color: '#04182B',
      fontSize: '2.7rem',
      fontWeight: 800,
    },
    h2: {
      color: '#04182B',
      fontSize: '1.953rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.563rem',
      },
    },
    h3: {
      color: '#04182B',
      fontSize: '1.5rem',
    },
    h4: {
      color: '#04182B',
      fontSize: '1.1rem',
    },
    h5: {
      color: '#04182B',
      fontSize: '0.8rem',
    },
    h6: {
      color: '#8dabbd',
      fontSize: '1.25rem',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    body2: {
      color: '#8dabbd',
      fontWeight: 500,
      fontSize: '1rem',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    body1: {
      color: '#04182B',
      fontSize: '1rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    subtitle1: {
      color: '#04182B',
      fontWeight: 500,
      fontSize: '1rem',

      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    button: {
      fontSize: '0.8rem',
      '@media (max-width:600px)': {
        fontSize: '0.64rem',
      },
    },
    subtitle2: {
      color: '#04182B',
      fontSize: '1.5rem',
    },
  },
  text: {
    primary: cyan,
  },
  status: {
    danger: 'orange',
  },
  shadows: [0],

  overrides: {
    MuiTab: {
      wrapper: {
        alignItems: 'end',
        fontSize: 25,
      },
    },
    MuiGrid: {
      container: {
        '&$spacing-xs-3': {
          width: '100%',
        },
      },
    },
  },
});

export default theme;
