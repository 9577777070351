import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const ExcelTable = ({ rows }) => {
  const headerRow = rows[0];
  const bodyRows = rows.slice(1);
  const classes = useStyles();

  return (
    <>
      <Table
        className={classes.table}
        aria-label="simple table"
        key="group-table"
      >
        <TableHead>
          <TableRow>
            {headerRow.map((title) => {
              return (
                <TableCell
                  style={{
                    fontSize: '0.8rem',
                    color: '#8dabbd',
                    fontWeight: '400',
                  }}
                >
                  {title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyRows.map((row) => {
            return (
              <TableRow>
                {row.map((item) => {
                  return (
                    <TableCell
                      className={'text-dark'}
                      align="right"
                      style={{ fontSize: '0.6rem' }}
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 300,
    overflowX: 'auto',
  },
});

export default ExcelTable;
