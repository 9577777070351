export function generateBorders(elements, heroItem, selectedItem) {
  const ele = [...elements];
  for (const node of ele) {
    if (node.data.id === heroItem) {
      node.data.borderColor = '#019062';
      node.data.borderWidth = 8;
    } else if (selectedItem && node.data._id === selectedItem.data._id) {
      node.data.borderColor = '#002144';
      node.data.borderWidth = 8;
    } else {
      node.data.borderColor = 'lightgray';
      node.data.borderWidth = 3;
    }
  }

  return ele;
}

export const nodeAndEdgeStyle = [
  {
    selector: 'node',
    style: {
      'background-image': 'data(displayPicture)',
      'background-fit': 'cover',
      'border-width': 'data(borderWidth)',
      'border-color': 'data(borderColor)',
      shape: 'roundrectangle',
      width: 200,
      height: 200,
    },
  },
  {
    selector: 'edge',
    style: {
      width: 4,
      'line-color': 'lightgray',
      'target-arrow-color': 'lightgray',
      'target-arrow-shape': 'triangle',
      'curve-style': 'bezier',
    },
  },
];

export const breadthfirst = {
  name: 'dagre',
};
