import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout, HomeLayout, ErrorLayout, NoLayout } from './Layouts';
//Components
import Loading from '../components/common/Loading';
import IdleTimerComponent from '../components/common/IdleTimerComponent';
//Pages
//import Home from '../pages/home-page/Home';
import ErrorPage from '../pages/ErrorPage';
import LandingPage from '../pages/landing-page/LandingPage';
import InfoPage from '../pages/sensor-pages/InfoPage';
import DnaPage from '../pages/DnaPage';
import HistoryPage from '../pages/history-page/HistoryPage';
import ARPage from '../pages/ar-page/ARPage';
import TrackPage from '../pages/TrackPage';
import TemperaturePage from '../pages/sensor-pages/TemperaturePage';
import EnergyPage from '../pages/sensor-pages/EnergyPage';
import VolumePage from '../pages/sensor-pages/VolumePage';
import WeightPage from '../pages/sensor-pages/WeightPage';
import TransfersPage from '../pages/TransfersPage';

//Redux
import { useSelector } from 'react-redux';
//Selectors
import { selectAccessabilityItem } from '../store/selectors';

export default function Routes() {
  const isPrivate = useSelector(selectAccessabilityItem);
  return (
    <BrowserRouter>
      {isPrivate && <IdleTimerComponent />}
      <Suspense fallback={<Loading />}>
        <Switch>
          {<Redirect exact from="/" to="/home" />}
          <RouteWrapper
            exact
            path="/home"
            //component={Home}
            component={() => {
              window.location.href = 'https://inlandsbryggeri.my.canva.site/';
              return null;
            }}
            layout={HomeLayout}
          />
          <RouteWrapper
            exact
            path="/:id"
            component={LandingPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/info/:id/:id2/:id3/:id4"
            component={LandingPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/info/:id/:id2"
            component={LandingPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/:id/:id2/:id3/:id4"
            component={LandingPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/:id/:id2"
            component={LandingPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/InfoPage/:id/:id2/:id3/:id4"
            component={InfoPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/InfoPage/:id/:id2"
            component={InfoPage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/dna/:id/:id2/:id3/:id4"
            component={DnaPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/dna/:id/:id2"
            component={DnaPage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/trace/:id/:id2/:id3/:id4"
            component={TrackPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/trace/:id/:id2"
            component={TrackPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/temperatures/:id/:id2/:id3/:id4"
            component={TemperaturePage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/temperatures/:id/:id2"
            component={TemperaturePage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/energy/:id/:id2/:id3/:id4"
            component={EnergyPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/energy/:id/:id2"
            component={EnergyPage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/weight/:id/:id2/:id3/:id4"
            component={WeightPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/weight/:id/:id2"
            component={WeightPage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/volume/:id/:id2/:id3/:id4"
            component={VolumePage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/volume/:id/:id2"
            component={VolumePage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/transfers/:id/:id2/:id3/:id4"
            component={TransfersPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/transfers/:id/:id2"
            component={TransfersPage}
            layout={Layout}
          />

          <RouteWrapper
            exact
            path="/HistoryPage/:id/:id2/:id3/:id4"
            component={HistoryPage}
            layout={Layout}
          />
          <RouteWrapper
            exact
            path="/HistoryPage/:id/:id2"
            component={HistoryPage}
            layout={Layout}
          />

          <RouteWrapper exact path="/ar" component={ARPage} layout={NoLayout} />
          <RouteWrapper
            exact
            path="/error"
            component={ErrorPage}
            layout={ErrorLayout}
          />
          <Redirect to="/error" layout={Layout} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
